<template>
  <div class="slider">
    <img src="@/assets/pictures/order/background.png"
         :style="{ left: `${this.backgroundLeft}px` }"
         class="slider__background_image" />
    <div class="slider-list">
      <div class="slider-track">
        <div class="slide">
          <img class="slider__slide1"
               :style="{width: `${this.images[0].finalWidth}px`,
               left: `${this.images[0].left}px`, top: `${this.images[0].top}px`}"
               rel="preload"
          src="@/assets/pictures/order/lastVers/detail1_mobile.webp" />
        </div>
        <div class="slide">
          <img class="slider__slide2"
               :style="{width: `${this.images[1].finalWidth}px`,
               left: `${this.images[1].left}px`, top: `${this.images[1].top}px`}"
               rel="preload"
               src="@/assets/pictures/order/lastVers/detail2_mobile.webp" />
        </div>
        <div class="slide">
          <img class="slider__slide3"
               :style="{width: `${this.images[2].finalWidth}px`,
                left: `${this.images[2].left}px`, top: `${this.images[2].top}px`}"
               rel="preload"
               src="@/assets/pictures/order/lastVers/detail3_mobile.webp" />
        </div>
        <div class="slide">
          <img class="slider__slide4"
               :style="{width: `${this.images[3].finalWidth}px`,
                left: `${this.images[3].left}px`, top: `${this.images[3].top}px`}"
               rel="preload"
               src="@/assets/pictures/order/lastVers/detail4_mobile.webp" />
        </div>
      </div>
    </div>
    <div class="slider-arrows" style="display: none">
      <button type="button" class="prev">&larr;</button>
      <button type="button" class="next">&rarr;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    index: Number,
    startTimer: Function,
    stopTimer: Function,
  },
  watch: {
    index: {
      handler(val) {
        this.slideIndex = val;
        this.slide();
      },
    },
  },
  computed: {
    m_md() {
      return this.$store.state.index.m_md;
    },
    m_lg() {
      return this.$store.state.index.m_lg;
    },
  },
  mounted() {
    this.width = window.innerWidth;
    this.setPadding();
    this.setImageParams();
    this.$store.watch((state) => state.index.width, () => {
      this.width = window.innerWidth;
      this.setPadding();
      this.setImageParams();
      this.slideWidth = this.slides[0].offsetWidth;
      this.sliderTrack.style.transition = 'transform 0s';
      this.sliderTrack.style.transform = `translate3d(-${this.slideIndex * this.slideWidth}px, 0px, 0px)`;
    });
    this.slider = document.querySelector('.slider');
    this.sliderList = this.slider.querySelector('.slider-list');
    this.sliderTrack = this.slider.querySelector('.slider-track');
    this.slides = this.slider.querySelectorAll('.slide');
    this.arrows = this.slider.querySelector('.slider-arrows');
    this.prev = this.arrows.children[0];
    this.next = this.arrows.children[1];
    this.slideWidth = this.slides[0].offsetWidth;
    this.lastTrf = (this.pictures - 1) * this.slideWidth;
    this.posThreshold = this.slides[0].offsetWidth * 0.35;
    this.sliderTrack.style.transform = 'translate3d(0px, 0px, 0px)';
    this.sliderList.classList.add('grab');
    this.sliderTrack.addEventListener('transitionend', () => this.allowSwipe = true);
    this.slider.addEventListener('touchstart', this.swipeStart, { passive: true });
    this.slider.addEventListener('mousedown', this.swipeStart);
    this.arrows.addEventListener('click', () => {
      const { target } = event;

      if (target.classList.contains('next')) {
        this.slideIndex++;
      } else if (target.classList.contains('prev')) {
        this.slideIndex--;
      } else {
        return;
      }
      this.slide();
    });
  },
  data() {
    return {
      width: 0,
      images: {
        0: {
          fullHeight: 1200,
          fullWidth: 1200,
          height: 565,
          width: 600,
          marginLeft: 298,
          marginTop: 200,
          finalWidth: 0,
          top: 0,
          left: 0,
        },
        1: {
          fullHeight: 1200,
          fullWidth: 1200,
          height: 587,
          width: 812,
          marginLeft: 202,
          marginTop: 270,
          finalWidth: 0,
          top: 0,
          left: 0,
        },
        2: {
          fullHeight: 1200,
          fullWidth: 1200,
          height: 537,
          width: 681,
          marginLeft: 178,
          marginTop: 270,
          finalWidth: 0,
          top: 0,
          left: 0,
        },
        3: {
          fullHeight: 1200,
          fullWidth: 1200,
          height: 490,
          width: 661,
          marginLeft: 253,
          marginTop: 320,
          finalWidth: 0,
          top: 0,
          left: 0,
        },
      },
      slider: null,
      sliderList: null,
      sliderTrack: null,
      slides: [],
      arrows: [],
      pictures: 4,
      prev: null,
      next: null,
      slideWidth: null,
      slideIndex: 0,
      posInit: 0,
      posX1: 0,
      posX2: 0,
      posY1: 0,
      posY2: 0,
      posFinal: 0,
      isSwipe: false,
      isScroll: false,
      allowSwipe: true,
      transition: true,
      nextTrf: 0,
      prevTrf: 0,
      lastTrf: null,
      posThreshold: null,
      trfRegExp: /([-0-9.]+(?=px))/,
      swipeStartTime: null,
      swipeEndTime: null,
      padding: 47,
      backgroundLeft: 93,
    };
  },
  methods: {
    getEvent() {
      return (event.type.search('touch') !== -1) ? event.touches[0] : event;
    },
    slide() {
      if (this.transition) {
        this.sliderTrack.style.transition = 'transform .5s';
      }
      this.sliderTrack.style.transform = `translate3d(-${this.slideIndex * this.slideWidth}px, 0px, 0px)`;

      this.prev.classList.toggle('disabled', this.slideIndex === 0);
      this.next.classList.toggle('disabled',
        this.slideIndex === this.pictures - 1);
    },
    swipeStart() {
      const evt = this.getEvent();

      if (this.allowSwipe) {
        this.swipeStartTime = Date.now();

        this.transition = true;

        this.nextTrf = (this.slideIndex + 1) * -this.slideWidth;
        this.prevTrf = (this.slideIndex - 1) * -this.slideWidth;

        this.posInit = evt.clientX;
        this.posX1 = evt.clientX;
        this.posY1 = evt.clientY;

        this.sliderTrack.style.transition = '';

        document.addEventListener('touchmove', this.swipeAction);
        document.addEventListener('mousemove', this.swipeAction);
        document.addEventListener('touchend', this.swipeEnd);
        document.addEventListener('mouseup', this.swipeEnd);

        this.sliderList.classList.remove('grab');
        this.sliderList.classList.add('grabbing');
      }
    },
    swipeAction() {
      const evt = this.getEvent();
      const style = this.sliderTrack.style.transform;
      const transform = +style.match(this.trfRegExp)[0];

      this.posX2 = this.posX1 - evt.clientX;
      this.posX1 = evt.clientX;

      this.posY2 = this.posY1 - evt.clientY;
      this.posY1 = evt.clientY;

      if (!this.isSwipe && !this.isScroll) {
        const posY = Math.abs(this.posY2);
        if (posY > 7 || this.posX2 === 0) {
          this.isScroll = true;
          this.allowSwipe = false;
        } else if (posY < 7) {
          this.isSwipe = true;
        }
      }

      if (this.isSwipe) {
        if (this.slideIndex === 0) {
          if (this.posInit < this.posX1) {
            this.setTransform(transform, 0);
            return;
          }
          this.allowSwipe = true;
        }

        // запрет ухода вправо на последнем слайде
        if (this.slideIndex === this.pictures - 1) {
          if (this.posInit > this.posX1) {
            this.setTransform(transform, this.lastTrf);
            return;
          }
          this.allowSwipe = true;
        }

        if (this.posInit > this.posX1 && transform < this.nextTrf
          || this.posInit < this.posX1 && transform > this.prevTrf) {
          this.reachEdge();
          return;
        }

        this.sliderTrack.style.transform = `translate3d(${transform - this.posX2}px, 0px, 0px)`;
      }
    },
    swipeEnd() {
      this.posFinal = this.posInit - this.posX1;

      this.isScroll = false;
      this.isSwipe = false;

      document.removeEventListener('touchmove', this.swipeAction);
      document.removeEventListener('mousemove', this.swipeAction);
      document.removeEventListener('touchend', this.swipeEnd);
      document.removeEventListener('mouseup', this.swipeEnd);

      this.sliderList.classList.add('grab');
      this.sliderList.classList.remove('grabbing');

      if (this.allowSwipe) {
        this.swipeEndTime = Date.now();
        if (Math.abs(this.posFinal) > this.posThreshold
          || this.swipeEndTime - this.swipeStartTime < 300) {
          if (this.posInit < this.posX1) {
            this.slideIndex--;
          } else if (this.posInit > this.posX1) {
            this.slideIndex++;
          }
        }

        if (this.posInit !== this.posX1) {
          this.allowSwipe = false;
          this.stopTimer();
          this.$store.commit('home/setActiveImage', this.slideIndex);
          this.$emit('setActiveImageMobile', this.slideIndex);
          setTimeout(() => {
            this.startTimer(this.slideIndex, 5000, true);
          }, 20000);
          this.slide();
        } else {
          this.allowSwipe = true;
        }
      } else {
        this.allowSwipe = true;
      }
    },
    setTransform(transform, compareTransform) {
      if (transform >= compareTransform) {
        if (transform > compareTransform) {
          this.sliderTrack.style.transform = `translate3d(${compareTransform}px, 0px, 0px)`;
        }
      }
      this.allowSwipe = false;
    },
    reachEdge() {
      this.transition = false;
      this.swipeEnd();
      this.allowSwipe = true;
    },
    setImageParams() {
      Object.keys(this.images).forEach((item) => {
        let x = 0;
        if (this.width <= this.m_lg) {
          x = this.images[item].width / (this.width - 67);
        } else {
          x = this.images[item].width / (this.m_lg - 67);
        }
        this.images[item].finalWidth = this.images[item].fullWidth / x;
        if (this.width <= this.m_lg) {
          this.images[item].left = (-this.images[item].marginLeft / x) + this.padding;
          this.images[item].top = -((
            this.images[item].marginTop / x
            + this.images[item].height / x)
            - 300);
        } else {
          this.images[item].left = (-this.images[item].marginLeft / x)
            + ((this.width / 2) - ((this.images[item].width / x) / 2));
          this.images[item].top = -((
            this.images[item].marginTop / x
            + this.images[item].height / x)
            - 315);
        }
      });
    },
    setPadding() {
      if (this.width <= this.m_md) {
        this.padding = 15;
        this.backgroundLeft = 93;
      } else if (this.width <= 576) {
        this.padding = 47;
        this.backgroundLeft = 93;
      } else {
        this.padding = 47;
        this.backgroundLeft = (this.width - 576) / 2;
      }
    },
  },
};
</script>
